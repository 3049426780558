import React from "react"
import { Link } from "gatsby"
import { ArrowRight } from "react-feather"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"
import { AffiliateList } from "../components/AffiliateList"
import { Compare } from "../components/Compare"
import { Reviews } from "../components/Reviews"
import { CTABlock } from "../components/CTABlock"
import award1 from "../assets/icons/award-1.svg"
import doctify from "../assets/images/doctify-logo.svg"
import award2 from "../assets/icons/award-2.svg"
import blogPlaceholder01 from "../assets/images/elizabeth-blog-01.jpg"
import blogPlaceholder02 from "../assets/images/elizabeth-blog-02.jpg"
import blogPlaceholder03 from "../assets/images/elizabeth-blog-03.jpg"
import blogPlaceholder04 from "../assets/images/elizabeth-blog-04.jpg"
import bA1b from "../assets/images/b-a-1-before.jpg"
import bA1a from "../assets/images/b-a-1-after.jpg"
import bA2b from "../assets/images/b-a-2-before.jpg"
import bA2a from "../assets/images/b-a-2-after.jpg"
import bA3b from "../assets/images/b-a-3-before.jpg"
import bA3a from "../assets/images/b-a-3-after.jpg"
import profile2 from "../assets/images/elizabeth-suit.jpg"
import profile3 from "../assets/images/elizabeth-red.jpg"
import serviceOculXs from "../assets/images/@1x-oculoplastic-xs.jpg"
import serviceOphthXs from "../assets/images/@1x-ophthalmology-xs.jpg"
import serviceAesthXs from "../assets/images/@1x-aesthetics-xs.jpg"
import check from "../assets/icons/check.svg"
import hello from "../assets/icons/hello.svg"
import dailyMail from "../assets/icons/daily-mail.svg"
import iconTrendingFlat from "../assets/icons/icon_trending_flat_white.svg"
import glamour from "../assets/icons/glamour.svg"
import telegraph from "../assets/icons/telegraph.svg"
import bbcRadio from "../assets/icons/bbc-radio.svg"
import mayfair from "../assets/icons/mayfair.svg"
import tatler from "../assets/icons/tatler.svg"
import signature from "../assets/images/signature.svg"
import stylist from "../assets/icons/stylist.svg"
import sundayTimes from "../assets/icons/sunday-times.svg"
import yahoo from "../assets/icons/yahoo.svg"
import buttons from "../styles/buttons.module.css"
import containerClasses from "../styles/container.module.css"
import starRate from "../assets/images/star_rate.svg"

const IndexPage = () => {
  return (
    <Layout page="Home">
      <SEO
        title="Dr Hawkes | Oculoplastic Surgery & Aesthetics in London"
        description="Dr Elizabeth Hawkes is a Consultant Ophthalmic and Oculoplastic Surgeon and Aesthetic Doctor practising on Sloane Street, London."
      />
      {/* Hero  Section */}
      <div className="relative background-panel custom-hero-height">
        <div
          className={`${containerClasses.container} grid pt-12 md:pt-16 h-full`}
        >
          <div className="absolute top-0 right-0 hidden lg:flex">
            <img alt="Dr Hawkes accolade 1" src={award1} className="mr-2" />
            <img alt="Dr Hawkes accolade 2" src={award2} />
          </div>
          <div className="col-span-4 text-center lg:text-left md:col-span-6 md:col-start-2 lg:col-start-1 lg:col-span-5 lg:flex lg:flex-col lg:justify-center">
            {/* <p className="overline">Dr Elizabeth Hawkes</p> */}
            <h1 className="mt-0 homepage-main">
              Blepharoplasty Expert, Eye Surgeon & Aesthetic Doctor.
              <br />
            </h1>
            <p className="mb-2 font-serif text-xl font-bold"> </p>
            <p className="mb-0 mb-2 text-center text-black lg:mb-4 lg:text-md lg:text-left">
              Award-winning London based Consultant Oculoplastic Surgeon and
              Aesthetic Practitioner.
            </p>
            <div className="flex flex-col items-center lg:items-start md:mb-8 w-28 lg:mb-12 xl:mb-16 md:flex md:justify-between">
              <Link
                className={`md:mr-1 text-center mt-4 md:mt-2 ${buttons.btnLargeExpressive} flex justify-between items-center`}
                to="/contact/"
              >
                <span className="inline">Book now</span>
                <img
                  loading="lazy"
                  className="inline ml-2 mr-0 h-15" // Added ml-2 for some spacing between the text and the icon
                  src={iconTrendingFlat}
                  alt="Right Arrow"
                />
              </Link>
              {/* <Link
                className={`block md:flex-grow md:ml-1 text-center mt-4 md:mt-2 ${buttons.btnLargeExpressiveOutline}`}
                to="/about/"
              >
                <span className="block lg:inline-block">About Dr Hawkes</span>
              </Link> */}
            </div>
          </div>
          <div className="col-span-4 md:col-span-6 md:col-start-2 lg:col-start-7 lg:col-span-5 lg:items-center"></div>
        </div>
      </div>
      {/* Logo Quilt 2 */}
      <div className="bg-white3">
        <div className="col-span-4 pt-8 pb-4 md:col-span-8 lg:col-span-12">
          <p className="mb-0 font-mono text-xs text-center text-gray-500">
            Press & Media Features
          </p>
          <div className="marquee">
            <div className="flex items-center marquee-content">
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={tatler}
                alt="Tatler Logo"
              />
              <img
                loading="lazy"
                className="w-20 h-16 mr-8"
                src={hello}
                alt="Helllo Magazine Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={mayfair}
                alt="Mayfair Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={dailyMail}
                alt="Daily Mail Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={glamour}
                alt="Glamour Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={yahoo}
                alt="Yahoo Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={sundayTimes}
                alt="The Sunday Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={stylist}
                alt="Stylist Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={telegraph}
                alt="The Telegraph Logo"
              />
              <img
                loading="lazy"
                className="w-16 h-16 mr-8"
                src={bbcRadio}
                alt="BBC Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={tatler}
                alt="Tatler Logo"
              />
              <img
                loading="lazy"
                className="w-20 h-16 mr-8"
                src={hello}
                alt="Helllo Magazine Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={mayfair}
                alt="Mayfair Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={dailyMail}
                alt="Daily Mail Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={glamour}
                alt="Glamour Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={yahoo}
                alt="Yahoo Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={sundayTimes}
                alt="The Sunday Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-1 mr-86"
                src={stylist}
                alt="science"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={telegraph}
                alt="The Telegraph Logo"
              />
              <img
                loading="lazy"
                className="w-16 h-16 mr-8"
                src={bbcRadio}
                alt="BBC Logo"
              />
              <img
                loading="lazy"
                className="w-20 h-16 mr-8"
                src={hello}
                alt="Helllo Magazine Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20"
                src={mayfair}
                alt="Mayfair Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={dailyMail}
                alt="Daily Mail Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={glamour}
                alt="Glamour Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={yahoo}
                alt="Yahoo Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={sundayTimes}
                alt="The Sunday Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={stylist}
                alt="Stylist Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={telegraph}
                alt="The Telegraph Logo"
              />
              <img
                loading="lazy"
                className="w-16 h-16 mr-8"
                src={bbcRadio}
                alt="BBC Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={tatler}
                alt="Tatler Logo"
              />
              <img
                loading="lazy"
                className="w-20 h-16 mr-8"
                src={hello}
                alt="Helllo Magazine Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={mayfair}
                alt="Mayfair Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={dailyMail}
                alt="Daily Mail Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={glamour}
                alt="Glamour Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={yahoo}
                alt="Yahoo Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={sundayTimes}
                alt="The Sunday Times Logo"
              />
              <img
                loading="lazy"
                className="w-24 h-16 mr-8"
                src={stylist}
                alt="science"
              />
              <img
                loading="lazy"
                className="w-24 h-20 mr-8"
                src={telegraph}
                alt="The Telegraph Logo"
              />
              <img
                loading="lazy"
                className="w-16 h-16 mr-8"
                src={bbcRadio}
                alt="BBC Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white2">
        <div
          className={`${containerClasses.container} py-12 md:py-24 lg:py-24`}
        >
          {/* Text */}
          <div className="flex flex-col col-span-4 md:col-span-8 lg:justify-center lg:col-span-5">
            <p className="mt-0 overline">Why choose Dr Hawkes?</p>
            <h2 className="mt-0 mb-6 fluid-heading-05">
              Bespoke care, <br />
              <i>natural</i> results.
            </h2>
            <ul>
              <li>
                <div className="flex">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mx-auto mr-2"
                    src={check}
                    alt="Checkmark"
                  />
                  <p>
                    <span className="font-bold">Bespoke Treatment:</span> Dr.
                    Hawkes meticulously formulates a personalised treatment plan
                    specifically tailored to your individual needs, medical
                    background, and aspirations.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mx-auto mr-2"
                    src={check}
                    alt="Checkmark"
                  />
                  <p>
                    <span className="font-bold">Excellence in Treatment:</span>{" "}
                    With over 20 years of medical and surgical training, Dr
                    Hawkes offers expertise in eye surgery, eye care and
                    aesthetic treatments.
                  </p>
                </div>
              </li>
              <li>
                <div className="flex">
                  <img
                    loading="lazy"
                    className="w-6 h-6 mx-auto mr-2"
                    src={check}
                    alt="Checkmark"
                  />
                  <p>
                    <span className="font-bold ">Top-tier Facility:</span> Dr
                    Hawkes is the lead Oculoplastic Surgeon at the
                    internationally renowned Cadogan Clinic, where your safety
                    and comfort are paramount.
                  </p>
                </div>
              </li>
            </ul>
            <div className="flex items-center mb-4">
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 -mr-1"
                src={starRate}
                alt="Star"
              />
              <img
                loading="lazy"
                className="h-5 mr-2"
                src={starRate}
                alt="Star"
              />
              <p className="mb-0 mr-2">
                <span className="font-bold">Rated 5/5</span>
              </p>
              <img loading="lazy" className="h-5" src={doctify} alt="Doctify" />
            </div>
          </div>
          {/* Image */}
          <div className="col-span-4 md:col-span-8 lg:col-start-7 lg:col-span-6">
            <img
              loading="lazy"
              className="w-full"
              alt="Dr Hawkes"
              src={profile2}
            />
          </div>
        </div>
      </div>
      <div className={`${containerClasses.container} `}>
        <div className="col-span-4 border-solid border-b-1 border-borderColour md:col-span-8 lg:col-span-12"></div>
      </div>
      {/* Services */}
      <div className="bg-white text-blackNew">
        <div
          className={`${containerClasses.container} py-12 md:py-20 lg:py-24`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-span-6 lg:col-start-4">
            <p className="my-0 text-center md:col-span-8 lg:mt-4 overline">
              Services
            </p>
            <h2 className="mt-4 mb-0 text-center fluid-heading-05">
              Expert care from an <i>award-winning</i> surgeon
            </h2>
          </div>
        </div>
      </div>
      {/* Service 1 */}
      <div className="">
        <div className={`${containerClasses.container} pt-0 pb-6`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-5">
            <img
              loading="lazy"
              alt="Dr Hawkes"
              className=""
              src={serviceOculXs}
            />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-8 lg:justify-center">
            <h3 className="mt-0 mb-2 fluid-heading-04 lg:mb-4">
              {" "}
              <span className="mr-2 text-gold">01/</span>
              Oculoplastic Surgery
            </h3>
            <p className="mt-0 mb-2 lg:mb-0 lg:mt-1">
              Dr Hawkes is renowned for her expertise in a variety of
              specialised Oculoplastic Surgical Procedures. With her vast
              experience and dedication, she offers:
            </p>
            <ul className="pb-0 pl-8 mt-2 text-sm font-light list-disc md:text-base">
              <li className="indent-10">Blepharoplasty Surgery</li>
              <li className="indent-10">Brow Lift Surgery</li>
              <li className="indent-10">
                Chalazions (Stys) Removal & Mangement
              </li>
              <li>Ectropion & Entropion Treatment</li>
              <li>Eyelid Lump Diagnosis & Removal</li>
              <li>Ptosis (Droopy Eyelid) Correction</li>
              <li>Watery Eye Solutions</li>
              <li>Xanthelasma Removal</li>
            </ul>
            {/* <Link
              className={`block mt-6 lg:inline-block md:w-1/2 md:mr-1 ${buttons.btnLargeExpressiveOutline}`}
              to="/contact/"
            >
              <span className="uppercase">Book now</span>
            </Link> */}
          </div>
        </div>
        {/* Service 2 */}
        <div className={`${containerClasses.container} py-6`}>
          <div className="col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-8">
            <img
              loading="lazy"
              alt="Dr Hawkes"
              className=""
              src={serviceOphthXs}
            />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-1 lg:row-start-1 lg:justify-center ">
            <h3 className="mt-0 mb-2 fluid-heading-04 lg:mb-4">
              <span className="mr-2 text-gold">02/</span>
              Ophthalmology
            </h3>
            <p className="mt-0 mb-2 lg:mb-0 lg:mt-1">
              As a distinguished Consultant Ophthalmologist, Dr Hawkes provides
              an extensive range of services tailored to address eye-related
              concerns, encompassing:
            </p>
            <ul className="pb-0 pl-8 mt-2 text-sm font-light list-disc md:text-base">
              <li>Blepharitis Assessment</li>
              <li>Blephex & Recurrent Sty Treatment</li>
              <li>Cataract Surgery</li>
              <li>Dry Eye Management</li>
              <li>General & Urgent Ophthalmology</li>
              <li>Laser Surgery – YAG Posterior Capsulotomy.</li>
              <li>Under Eye Bag Solutions</li>
            </ul>
            {/* <Link
              className={`block mt-6 lg:inline-block md:w-1/2 md:mr-1 ${buttons.btnLargeExpressiveOutline}`}
              to="/contact/"
            >
              <span className="uppercase">Book now</span>
            </Link> */}
          </div>
        </div>
        {/* Service 3 */}
        <div
          className={`${containerClasses.container} pt-6 pb-12 md:pt-12 md:pb-24`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-span-5">
            <img
              loading="lazy"
              alt="Dr Hawkes"
              className=""
              src={serviceAesthXs}
            />
          </div>
          <div className="flex flex-col col-span-4 md:col-span-8 lg:col-span-5 lg:col-start-8 lg:justify-center ">
            <h3 className="mt-0 mb-2 fluid-heading-04 lg:mb-4">
              <span className="mr-2 text-gold">03/</span>
              Aesthetics
            </h3>
            <p className="mt-0 mb-2 lg:mb-0 lg:mt-1">
              Dr Elizabeth Hawkes is renowned for her array of advanced
              aesthetic treatments tailored to address various aesthetic
              concerns. Her offerings are as follows:
            </p>
            <ul className="pb-0 pl-8 mt-2 text-sm font-light list-disc md:text-base">
              <li className="indent-10">Anti-Wrinkle Injections</li>
              <li className="indent-10">AQUAGOLD® Facial</li>
              <li className="indent-10">Dark Circle Solutions</li>
              <li>Dermal Fillers Non-Surgical Brow Lift</li>
              <li>Polynucleotides</li>
              <li>PRP (Platelet Rich Plasma) Facial</li>
              <li>Profhilo®</li>
              <li>Sculptra</li>
              <li>Tear Trough Fillers</li>
            </ul>
            {/* <Link
              className={`block mt-6 lg:inline-block md:w-1/2 md:mr-1 ${buttons.btnLargeExpressiveOutline}`}
              to="/contact/"
            >
              <span className="uppercase">Book now</span>
            </Link> */}
          </div>
        </div>
      </div>
      <div className={`${containerClasses.container} `}>
        <div className="col-span-4 border-solid border-b-1 border-borderColour md:col-span-8 lg:col-span-12"></div>
      </div>

      {/* Homepage Quote */}
      <div className="relative py-24 overflow-hidden md:py-40 lg:py-48 xl:py-64 background-cover-home">
        <div className="absolute top-0 right-0 w-full h-full bg-black opacity-50"></div>
        <div className={`${containerClasses.container} relative z-10`}>
          {" "}
          {/* Adjusted z-index */}
          <div className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
            <h2 className="my-0 text-center text-white fluid-heading-05 animate-fadeIn">
              &ldquo;I am dedicated to delivering <i>exceptional care</i>,
              tailored to my patient&rsquo;s unique needs.&rdquo;
            </h2>
            <p className="mt-4 font-mono text-center text-white animate-slideIn">
              – Dr Elizabeth Hawkes
            </p>
          </div>
        </div>
      </div>
      {/* YouTube iframe */}
      <div className="py-12 bg-white2 md:py-24 lg:py-24">
        <div
          className={`${containerClasses.container} border-solid border-b-1 border-borderColour py-12 md:py-24 lg:py-24`}
        >
          <div className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
            <iframe
              width="100%"
              height="500"
              src="https://www.youtube.com/embed/O_gSPj2enDI?si=Ed80cn9H9pkLmC9G"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      {/* Before and afters */}
      <div className="py-12 md:py-24 lg:py-24">
        <div className="bg-white">
          <div
            className={`${containerClasses.container} border-solid border-b-1 border-borderColour py-12 md:py-24 lg:py-24`}
          >
            <div className="col-span-4 md:col-span-8 lg:col-span-10 lg:col-start-2">
              <p className="my-0 text-center md:col-span-8 overline">
                Before & After
              </p>
              <div className=" lg:col-span-6 lg:col-start-4">
                <h2 className="mb-4 text-center fluid-heading-05">
                  Real patients, <i>tangible</i> results.
                </h2>
              </div>
              <div className="grid gap-4 mt-4 md:grid-cols-3">
                <Compare imageOne={bA2b} imageTwo={bA2a} className="" />
                <Compare imageOne={bA3b} imageTwo={bA3a} className="mb-2" />
                <Compare imageOne={bA1b} imageTwo={bA1a} className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      <Reviews />

      {/* About Section */}
      <div className="bg-white text-blackNew">
        <div
          className={`${containerClasses.container} py-12 md:py-24 lg:py-24`}
        >
          {/* About Section: Text */}
          <div className="flex flex-col items-start col-span-4 lg:justify-center md:col-span-8 lg:col-span-5">
            <p className="mt-0 overline">Dr Hawkes FRCOphth MBBS BSc</p>
            <h2 className="mt-0 mb-6 fluid-heading-05">
              Meet <i>the Surgeon</i>
            </h2>
            <p>
              Hello, I am Dr Elizabeth Hawkes. I specialise in eye and eyelid
              surgery, focusing on blepharoplasty and facial aesthetics. My
              approach combines advanced techniques tailored to each
              patient&apos;s anatomy to achieve natural face and eye
              rejuvenation. For more insights into my work and techniques,
              please visit my{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/@DrElizabethHawkes"
              >
                YouTube Channel
              </a>
              . I&apos;m truly grateful for the recognition I&apos;ve received
              through various industry awards and look forward to meeting you in
              consultation.
            </p>
            {/* About Section: Image */}
            <img
              loading="lazy"
              className="h-10 my-0"
              src={signature}
              alt="Doctify"
            />
            <Link
              className={`text-center w-full block mt-4 mb-8 lg:inline-block md:mr-1 lg:w-auto ${buttons.btnLargeExpressive}`}
              to="/about/"
            >
              <div className="flex items-center justify-between">
                About Miss Hawkes
                <ArrowRight className="ml-10" size="16" />
              </div>
            </Link>
          </div>
          <div className="col-span-4 md:col-span-8 lg:col-span-6 lg:col-start-7">
            <img
              loading="lazy"
              className="w-full"
              alt="Dr Hawkes"
              src={profile3}
            />
          </div>
        </div>
      </div>
      {/* Blog feed title*/}
      <div className="bg-white2 md:hidden lg:block">
        <div
          className={`${containerClasses.container} py-10 md:py-24 lg:py-24`}
        >
          <p className="col-span-4 mb-0 md:col-span-8 lg:col-span-12 overline">
            Featured
          </p>
          <h2 className="col-span-4 mt-0 mb-4 text-4xl font-bold lg:col-span-12 md:col-span-8 fluid-heading-05">
            Blog Posts
          </h2>

          {/* First post */}
          <div className="relative flex col-span-4 mb-2 shadow-md lg:mb-0 lg:flex-grow lg:row-span-2 lg:flex lg:col-span-6">
            <a
              href="https://drelizabethhawkes.com/blog/dr-hawkes-tatler-addition"
              className="block bg-white"
            >
              <div className="bg-white ">
                <img
                  loading="lazy"
                  className="object-cover w-full"
                  alt="Dr Hawkes"
                  src={blogPlaceholder01}
                />
                <div className="p-8">
                  <p
                    className="absolute flex flex-col justify-center tag"
                    style={{ top: "20px", left: "20px" }}
                  >
                    News
                  </p>
                  <p className="mb-1 font-mono text-xs text-gray-500 uppercase">
                    {" "}
                    Dec 2023
                  </p>
                  <h3 className="mb-1 text-xl leading-snug lg:text-2xl xl:text-3xl xl:mb-2">
                    Dr Elizabeth Hawkes: The Latest Addition to Tatler&apos;s
                    Address Book!
                  </h3>

                  <p className="hidden text-gray-600 lg:block xl:mb-5">
                    Dr. Elizabeth Hawkes has been honoured with a distinguished
                    spot in Tatler&apos;s Address Book, marking her one of the
                    top professionals in her field.
                  </p>
                </div>
              </div>
            </a>
          </div>
          {/* Second post */}
          <div className="col-span-4 med:col-span-8 lg:col-span-6 lg:col-start-7 lg:row-span-2">
            <div className="relative col-span-4 bg-white shadow-md md:col-span-8 lg:col-span-2 lg:mb-4 lg:col-start-7 lg:row-span-1 lg:row-start-3">
              <a
                href="https://drelizabethhawkes.com/blog/the-secret-to-beautiful-eyes-ii:-dark-circles"
                className="block h-full"
              >
                <div className="flex flex-col h-full mb-6 lg:flex-row">
                  <img
                    loading="lazy"
                    className="object-cover w-full h-1/3 lg:w-1/2"
                    alt="Under-Eye Puffiness"
                    src={blogPlaceholder03}
                  />
                  <p
                    className="absolute flex flex-col justify-center tag"
                    style={{ top: "20px", left: "20px" }}
                  >
                    Eyes{" "}
                  </p>
                  <div className="flex-grow p-8">
                    <div className="flex flex-row items-center content-center mt-1 mb-0 mb-2 text-royalBlue">
                      <p className="mt-0 mb-0 font-mono text-xs leading-none text-gray-500 uppercase">
                        {" "}
                        Nov 2022
                      </p>
                    </div>
                    <h2 className="flex-grow mt-1 mb-2 text-lg font-bold fluid-heading-03">
                      The Secret to Beautiful Eyes Part II: Dark Circles
                    </h2>
                  </div>
                </div>
              </a>
            </div>
            {/* Third post */}
            <div className="relative col-span-4 bg-white shadow-md md:col-span-8 lg:col-span-2 lg:mb-4 lg:col-start-7 lg:row-span-1 lg:row-start-2">
              <a
                href="https://drelizabethhawkes.com/blog/the-secret-to-beautiful-eyes-iii-expert-solutions-for-under-eye-puffiness"
                className="block h-full"
              >
                <div className="flex flex-col h-full mb-6 lg:flex-row">
                  <img
                    loading="lazy"
                    className="object-cover w-full h-1/3 lg:w-1/2"
                    alt="Under-Eye Puffiness"
                    src={blogPlaceholder02}
                  />
                  <p
                    className="absolute flex flex-col justify-center tag"
                    style={{ top: "20px", left: "20px" }}
                  >
                    Eyes{" "}
                  </p>
                  <div className="flex-grow p-8">
                    <div className="flex flex-row items-center content-center my-1 text-royalBlue">
                      <p className="my-1 font-mono text-xs leading-none text-gray-500 uppercase">
                        {" "}
                        May 2023
                      </p>
                    </div>
                    <h2 className="flex-grow mt-1 mb-2 text-lg font-bold fluid-heading-03">
                      The Secret to Beautiful Eyes Part III: Under-Eye Puffiness
                    </h2>
                  </div>
                </div>
              </a>
            </div>
            {/* Fourth post */}
            <div className="relative flex flex-col justify-between flex-grow col-span-4 mb-4 bg-white shadow-md lg:col-start-7 lg:row-start-5 lg:w-full lg:flex-row lg:mb-0 lg:col-span-6">
              <a
                href="https://drelizabethhawkes.com/blog/the-secret-to-beautiful-eyes-iv:-expert-tips-for-longer-lashes"
                className="block"
              >
                <div className="flex flex-col justify-between lg:flex-row">
                  <img
                    loading="lazy"
                    className="object-cover w-full h-1/3 lg:w-1/2"
                    alt="Dr Hawkes"
                    src={blogPlaceholder04}
                  />
                  <p
                    className="absolute flex flex-col justify-center tag"
                    style={{ top: "20px", left: "20px" }}
                  >
                    Eyes{" "}
                  </p>
                  <div className="flex-grow p-8">
                    <div className="flex flex-row items-center content-center mt-1 mb-0 mb-2 text-royalBlue">
                      <p className="my-1 font-mono text-xs leading-none text-gray-500 uppercase">
                        {" "}
                        Aug 2023
                      </p>
                    </div>
                    <h2 className="flex-grow mt-1 mb-2 text-lg font-bold fluid-heading-03">
                      The Secret to Beautiful Eyes Part IV: Expert Tips for
                      Longer Lashes
                    </h2>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <CTABlock />
      <AffiliateList />
    </Layout>
  )
}

export default IndexPage
